<template>
  <div>
    <b-card
      no-body
      class="mb-0 pb-0"
    >
      <div class="py-1 px-2 d-flex justify-content-between">
        <div v-html="'Tìm thấy ' + (totalProducts || 0) + ' sản phẩm sắp hết hạn'" />
        <table-column-toggle
          id="refProductListTable"
          :table-columns="tableColumns"
          @update-columns-show="updateTableColumns"
        />
      </div>
      <b-table
        ref="refProductListTable"
        class="position-relative custom-head thead-no-wrap line-height-12 font-small-4"
        responsive
        hover
        primary-key="id"
        show-empty
        small
        empty-text="Không có kết quả nào"
        :items="products"
        :fields="tableColumns"
        @row-clicked="rowClicked"
      >
        <template #cell(id)="data">
          <span class="d-block font-weight-bold text-primary">{{ data.item.id }}</span>
          <div class="product-badge-wrapper">
            <b-badge
              v-if="data.item.is_hot"
              class="product-badge hot"
              variant="danger"
            >HOT
            </b-badge>
            <b-badge
              v-if="data.item.has_corner"
              variant="success"
              class="product-badge corner"
            >GÓC
            </b-badge>
          </div>
        </template>

        <template #cell(image)="data">
          <b-media-aside class="mr-75">
            <b-avatar
              square
              size="64"
              :src="data.item.image ? getUrlFile(data.item.image, data.item.updated_at, showImage160.includes(data.item.image || '') ? '240' : '160') : ''"
              :text="data.item.image ? '' : 'CL'"
              @img-error="() => showImage160.push(data.item.image)"
            />
          </b-media-aside>
        </template>

        <template #cell(title)="data">
          <div
            class="py-50 font-small-3"
            :class="data.item.close_deal_type === 'close-deal' ? 'text-danger' : 'text-primary'"
          >
            <template v-if="data.item.close_deal_type === 'close-deal'">
              [CLOSE DEAL]
            </template>{{ data.item.title }}
          </div>
        </template>

        <template #cell(ket-cau)="data">
          <div class="d-flex flex-wrap maxw-150">
            <span
              v-if="data.item.width && data.item.length"
              class="text-nowrap"
            >{{ data.item.width + 'x' + data.item.length + 'm' }}, </span>
            <span
              v-if="!!data.item.basement"
              class="text-nowrap"
            >1 hầm, </span>
            <span
              class="text-nowrap"
            > 1 trệt, </span>
            <span
              v-if="!!data.item.badger"
              class="text-nowrap"
            >1 lửng, </span>
            <span
              v-if="!!data.item.floor_number"
              class="text-nowrap"
            >{{ data.item.floor_number + ' lầu' }}, </span>
            <span
              v-if="!!data.item.terrace"
              class="text-nowrap"
            >ST</span>
          </div>
        </template>

        <template #cell(price)="data">
          <div class="text-primary text-nowrap">
            <span class="font-weight-bold">
              {{ formatNumber(data.item.input_price) }} <small>{{ (CURRENCY.find(item => item.value === data.item.currency) || {text: 'usd'}).text.toUpperCase() }}</small>
            </span>
            <template v-if="data.item.type_of_payment === 'month'">
              <br>
              / tháng
            </template>
          </div>
        </template>

        <template #cell(nguoi-dang)="data">
          {{ data.item.user.fullname }}
          <div class="product-badge-wrapper">
            <b-badge
              v-if="data.item.is_hidden_phone"
              variant="success"
              class="product-badge hidden-phone"
            >ẨN SỐ
            </b-badge>
          </div>
        </template>

        <template #cell(type)="data">
          <small class="text-nowrap">{{
            (TYPE_PRODUCT.find(item => item.value === data.item.type) || {}).text || ''
          }}</small>
        </template>

        <template #cell(thang-may)="data">
          {{ data.item.elevator ? 'Có' : 'Không' }}
        </template>

        <template #cell(expand_style)="data">
          {{ (EXPAND_STYLE.find(item => item.value === data.item.expand_style) || {}).text || '' }}
          <template v-if="data.item.expand_style_info">
            <br>
            ({{ data.item.expand_style_info }} m)
          </template>
        </template>

        <template #cell(ngay-dang)="data">
          {{ formatDate(data.item.created_at) }}
        </template>

        <template #cell(actions)="data">
          <div class="product-actions-wrapper">
            <b-button
              v-if="ability().can('edit', subject('ProductHired', data.item))"
              variant="outline-primary"
              size="sm"
              class="p-50"
              @click="gotoProductEdit(data.item)"
            >
              <feather-icon
                icon="EditIcon"
              />
            </b-button>
            <b-button
              v-if="ability().can('delete', subject('ProductHired', data.item))"
              variant="outline-danger"
              size="sm"
              class="p-50"
              @click="openModalConfirmDeleteProduct(data.item)"
            >
              <feather-icon
                icon="TrashIcon"
              />
            </b-button>
          </div>
        </template>

      </b-table>

      <div class="mb-3">
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start mb-1 mb-md-0"
          >
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>mỗi trang</label>
          </b-col>

          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalProducts"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BAvatar, BBadge, BButton, BCard, BCol, BMediaAside, BPagination, BRow, BTable, VBTooltip,
} from 'bootstrap-vue'
import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import vSelect from 'vue-select'
import productStoreModule from '@/views/product/productStoreModule'
import TableColumnToggle from '@core/components/TableColumnToggle.vue'
import ability from '@/libs/acl/ability'
import { subject } from '@casl/ability'
import useProductList from './useProductList'

export default {
  components: {
    BAvatar,
    BCard,
    BTable,
    TableColumnToggle,
    BPagination,
    vSelect,
    BRow,
    BCol,
    BMediaAside,
    BBadge,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      loading: false,
      ability,
      subject,
    }
  },
  created() {
    const { filter } = this.$route.query
    Object.assign(this.filter, JSON.parse(filter || '""'))
    store.dispatch('app/fetchUsers')
    this.fetchExpireProducts().finally(() => {
      this.loading = false
    })
    // waiting for watching done
    setTimeout(() => { this.created = true }, 500)
  },
  methods: {
    gotoProductEdit(product) {
      this.$router.push({ name: `product-${this.productMenuType}-edit`, params: { id: product.id } })
    },
    openModalConfirmDeleteProduct(product) {
      this.currentProduct = product
      this.$refs['modal-confirm-delete-product'].show()
    },
    handleDeleteProduct() {
      this.$call(this.deleteProduct(this.currentProduct.id), true).then(() => {
        const foundIdx = this.products.findIndex(item => item.id === this.currentProduct.id)
        if (foundIdx !== -1) {
          this.products.splice(foundIdx, 1)
        }
      })
    },
    gotoProductDetail({ id }) {
      const route = this.$router.resolve({
        name: `product-${this.productMenuType}-detail`,
        params: { id },
      })
      window.open(route.href)
    },
    handleSearch() {
      this.$router.replace({
        query: {
          filter: JSON.stringify(this.filter || ''),
        },
      })
      this.fetchExpireProducts().finally(() => {
        this.loading = false
      })
    },
    handleClearFilter(initFilter) {
      this.clearFilter(initFilter)
    },
    updateTableColumns(tableColumns) {
      this.tableColumns = tableColumns
    },
    rowClicked(row) {
      this.gotoProductDetail(row)
    },
  },
  setup() {
    const PRODUCT_STORE_MODULE_NAME = 'product'
    if (!store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.registerModule(PRODUCT_STORE_MODULE_NAME, productStoreModule)
    onUnmounted(() => {
      if (store.hasModule(PRODUCT_STORE_MODULE_NAME)) store.unregisterModule(PRODUCT_STORE_MODULE_NAME)
    })

    return {
      ...useProductList(),
    }
  },
}
</script>

<style lang="scss">
.per-page-selector {
  width: 90px;
}

.custom-head th {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.table .feather {
  min-width: 1rem;
}

.product-badge {
  position: absolute;
  right: 0;
  font-size: .7rem !important;

  &.hot {
    top: .25rem;
  }

  &.corner {
    bottom: .25rem;
  }
}

.product-actions-wrapper {
  display: flex;
  gap: 1rem;
  justify-content: flex-end;

  > * {
    padding: .25rem !important;
  }
}
</style>
